// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedMerchant: null,
    selectedMerchantAllTransactions: [],
    selectedMerchantTransactions: [],
    selectedMerchantTotalTransactions: 1,
    selectedMerchantTransactionParams: {},
  }
  
  const merchants = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_MERCHANT_DATA':
        return { ...state, allData: action.data }
      case 'GET_FILTERED_MERCHANT_DATA':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_MERCHANT':
        return { ...state, selectedMerchant: action.selectedMerchant }
        case 'GET_MERCHANT_ALL_TRANSACTIONS':
          return { 
            ...state, 
            selectedMerchantAllTransactions: action.data
          }
        case 'GET_MERCHANT_TRANSACTIONS':
          return {
            ...state,
            selectedMerchantTransactions: action.data,
            selectedMerchantTotalTransactions: action.totalPages,
            selectedMerchantTransactionParams: action.params
          }
      default:
        return { ...state }
    }
  }
  export default merchants
  